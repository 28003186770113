import React, { useEffect, useRef } from "react";

const Skills =() =>{

	const progressBarRef = useRef(null);

  useEffect(() => {
    const progressBars = progressBarRef.current.querySelectorAll(".progress-bar");
    progressBars.forEach((bar) => {
      const width = bar.getAttribute("aria-valuenow");
      bar.style.width = `${width}%`;
    });
  }, []);
    return(<>
        <section className="skills mt-95">
				<div className="skill-content">
					<div className="section-heading text-center">
						<h2>skills</h2>
					</div>
					<div className="container">
						<div ref={progressBarRef}>
						<div className="row">
							<div className="col-md-6">
								<div className="single-skill-content">
										<div className="barWrapper">
											<span className="progressText">dot net</span>
											<div className="single-progress-txt">
												<div className="progress ">
													<div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="10" aria-valuemax="100" >
														
													</div>
												</div>
												<h3>90%</h3>	
											</div>
										</div>
									 <div className="barWrapper">
										<span className="progressText">C#</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="10" aria-valuemax="100" >
												    
											   </div>
											</div>
											<h3>85%</h3>	
										</div>
									</div>
									<div className="barWrapper">
										<span className="progressText">React</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="10" aria-valuemax="100" >
												   
											   </div>
											</div>
											<h3>50%</h3>	
										</div>
									</div>
									<div className="barWrapper">
										<span className="progressText">Laravel</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="10" aria-valuemax="100" >
												    
											   </div>
											</div>
											<h3>80%</h3>	
										</div>
									</div> 
								</div>
							</div>
							<div className="col-md-6">
								<div className="single-skill-content">
									<div className="barWrapper">
										<span className="progressText">sql</span>
										<div className="single-progress-txt">
											<div className="progress ">
												<div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="10" aria-valuemax="100" >
													
												</div>
											</div>
											<h3>90%</h3>	
										</div>
									</div>
									<div className="barWrapper">
										<span className="progressText">HTML</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="10" aria-valuemax="100" >
												    
											   </div>
											</div>
											<h3>90%</h3>	
										</div>
									</div>
									<div className="barWrapper">
										<span className="progressText">JavaScript</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="97" aria-valuemin="10" aria-valuemax="100" >
												   
											   </div>
											</div>
											<h3>97%</h3>	
										</div>
									</div>
									<div className="barWrapper">
										<span className="progressText"> PHP</span>
										<div className="single-progress-txt">
											<div className="progress ">
											   <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="10" aria-valuemax="100" >
												    
											   </div>
											</div>
											<h3>80%</h3>	
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>	
					</div>	
				</div>

		</section>
    </>)
}

export default Skills;