import React from "react";
import { Link } from "react-router-dom";


const Home =() =>{
    return(
        <><section id="welcome-hero" className="welcome-hero">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="header-text">
                        <h2>
                        hi <span>,</span> i am <br/> Hari  <span>.</span>  
                        </h2>
                        <p>Full stack web developer</p>
                        <a href="/assets/download/HarikrishnanE_compressed.pdf" download>
        Download Resume
      </a>
                    </div>
                </div>
            </div>
        </div>

         </section>
    

    
    <section id="about" className="about">
        <div className="section-heading text-center">
            <h2>about me</h2>
        </div>
        <div className="container">
            <div className="about-content">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="single-about-txt">
                            <h3>
                            Senior Software Developer | Expert in Backend Development (.NET,.NET Core) & React.js | Strong Experience in Database Management, Automation, and Data Visualization | Results-Driven Technology Leader
                            </h3>
                            <p>
                            As a seasoned software developer with 6 years of experience in backend development and 1.5 years of expertise in React.js, I possess a strong background in designing, developing, and maintaining web applications. My proficiency in .NET Core and react has enabled me to improve system performance and user experience. Additionally, I have a solid foundation in database management, with experience in designing and implementing database schemas, stored procedures, and queries using T-SQL.
I have a proven track record of collaborating with teams, participating in Agile methodologies such as sprint planning, daily stand-ups, and retrospectives. I am committed to ensuring code quality, security, and scalability through rigorous code reviews and implementation of best practices.
My experience extends to developing and maintaining automations, data fetching, and visualization, including a Sorting Visualizer project in JavaScript. I have also successfully fetched and utilized data from external sources, such as the US Department of Transportation sites, to meet project requirements.
With a strong passion for staying up-to-date with industry trends and technologies, I am confident in my ability to leverage my skills to drive innovative solutions and deliver high-quality results.
                            </p>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="single-about-add-info">
                                        <h3>phone</h3>
                                        <p>
  <a href="tel:8129339575">812-933-9575</a>
</p>
                                    </div>
                                </div>
                                
                                <div className="col-sm-4">
                                    <div className="single-about-add-info">
                                        <h3>website</h3>
                                        <p>
                                    <a href="https://harii.in" target="_blank" rel="noopener noreferrer">
                                        www.harii.in
                                    </a>
                                    </p>

                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="single-about-add-info">
                                        <h3>email</h3>
                                        <p>
                                        <a href="mailto:harikrishnan9256@gmail.com">harikrishnan9256@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-offset-1 col-sm-5">
                        <div className="single-about-img">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/myimg.jpg`} alt="profile_image" />
                            <div className="about-list-icon">
                                <ul>
                                <li><Link to="https://www.facebook.com/harikrishnan.e"  target="_blank" rel="noopener noreferrer"><i  className="fa fa-facebook" aria-hidden="true"></i></Link></li>
										<li><Link to="https://www.linkedin.com/in/harikrishnan-e-3b45891a1/" target="_blank" rel="noopener noreferrer"> <i  className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
										<li><Link to="https://www.instagram.com/harimysself/"  target="_blank" rel="noopener noreferrer"><i  className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                                        
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section></>
    );
}


export default Home;