import React from "react";
import { Link } from "react-router-dom";


const Clients = ()=>{
    return(<>
    <section id="clients" class="clients mt-90">
			<div class="section-heading text-center">
				<h2>clients</h2>
			</div>
			<div class="clients-area">
				<div class="container">
					<div class="owl-carousel owl-theme" id="client">
						<div class="item">
							<Link to="#">
								<img src={`${process.env.PUBLIC_URL}/assets/images/clients/c1.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
						<Link to="#">

                                <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c2.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
							<Link href="#">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c3.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
							<Link href="#">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c4.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
							<Link href="#">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c5.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
							<Link href="#">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c6.png`} alt="brand-image" />
							</Link>
						</div>
						<div class="item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/clients/c7.png`} alt="brand-image" />
						</div>
					</div>
				</div>
			</div>

		</section>
    </>)
}

export default Clients;