import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import Header from './Components/Shared/Header';
import Education from './Components/Education';
import Skills from './Components/Skills';
import Experience from './Components/Experience';
import Profile from './Components/Profile';
import Clients from './Components/Clients';
import Contact from './Components/Contact';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/education' element={<Education />} />
        <Route path='/skills' element={<Skills />} />
        <Route path='/experience' element={<Experience />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </Router>
  );
}
export default App;
