import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const Contact = () => {
    const [formData, SetFormData] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Subject: "",
        Message: ""
    });
    const [loading, setLoading] = useState(false); // State for loading button

    const handleChange = (e) => {
        const { name, value } = e.target;
        SetFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            if (result.success) {
                toast.success(result.message || "Message sent successfully!");
                SetFormData({ Name: "", Email: "", Phone: "", Subject: "", Message: "" }); // Reset form
            } else {
                toast.error(result.message || "Failed to send message.");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while sending your message. Please try again later.");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            <ToastContainer /> {/* Toast container for notifications */}
            <section id="contact" className="contact mt-95">
                <div className="section-heading text-center">
                    <h2>Contact Me</h2>
                </div>
                <div className="container">
                    <div className="contact-content">
                        <div className="row">
                            <div className="col-md-offset-1 col-md-5 col-sm-6">
                                <div className="single-contact-box">
                                    <div className="contact-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            placeholder="Name*"
                                                            value={formData.Name}
                                                            required
                                                            onChange={handleChange}
                                                            name="Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            placeholder="Email*"
                                                            value={formData.Email}
                                                            required
                                                            onChange={handleChange}
                                                            name="Email"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="subject"
                                                            placeholder="Subject"
                                                            value={formData.Subject}
                                                            onChange={handleChange}
                                                            name="Subject"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control"
                                                            rows="8"
                                                            id="comment"
                                                            placeholder="Message"
                                                            value={formData.Message}
                                                            onChange={handleChange}
                                                            name="Message"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="single-contact-btn">
                                                        <button
                                                            type="submit"
                                                            className="contact-btn"
                                                            disabled={loading}
                                                        >
                                                            {loading ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
				<div className="col-md-offset-1 col-md-5 col-sm-6">
							<div className="single-contact-box">
								<div className="contact-adress">
									<div className="contact-add-head">
										<h3>Hari</h3>
										<p>Full Stack Develper</p>
									</div>
									<div className="contact-add-info">
										<div className="single-contact-add-info">
											<h3>phone</h3>
											<p>
  <a href="tel:8129339575">812-933-9575</a>
</p>
										</div>
										<div className="single-contact-add-info">
											<h3>email</h3>
											<p>
  <a href="mailto:harikrishnan9256@gmail.com">harikrishnan9256@gmail.com</a>
</p>
										</div>
										<div className="single-contact-add-info">
											<h3>website</h3>
											<p>
                                        <a href="https://harii.in" target="_blank" rel="noopener noreferrer">
                                            www.harii.in
                                        </a>
                                        </p>

										</div>
									</div>
								</div>
								<div className="hm-foot-icon">
									<ul>
										<li><Link to="https://www.facebook.com/harikrishnan.e" className="fa fa-facebook"  target="_blank" rel="noopener noreferrer"></Link></li>
										<li><Link to="https://www.linkedin.com/in/harikrishnan-e-3b45891a1/" className="fa fa-linkedin" target="_blank" rel="noopener noreferrer"></Link></li>
										<li><Link to="https://www.instagram.com/harimysself/" className="fa fa-instagram" target="_blank" rel="noopener noreferrer"></Link></li>
									</ul>
								</div>
							</div>
						</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
